import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const [text, setText] = useState("Fetching")
  const [username, setUsername] = useState("Not logged in")

  useEffect(() => {
    async function getUserInfo() {
      const response = await fetch("/.auth/me")
      const payload = await response.json()
      const { clientPrincipal } = payload
      console.log("clientPrincipal", clientPrincipal.userDetails)

      return clientPrincipal
    }

    getUserInfo()
      .then(clientPrincipal => {
        debugger
        setUsername(clientPrincipal.userDetails)
      })
      .catch(err => {
        debugger
      })
  }, [])

  useEffect(() => {
    fetch(`/api/message?name=Craig`)
      .then(apiResponse => {
        apiResponse
          .json()
          .then(jsonResponse => {
            const { text } = jsonResponse

            setText(text)
          })
          .catch(err => {
            debugger
          })
      })
      .catch(err => {
        debugger
      })
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Obvious change to check pipeline.... {username}</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
      <p>
        Loading content from the API: <b>{text}</b>
      </p>
    </Layout>
  )
}

export default IndexPage
